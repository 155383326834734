import styled from 'styled-components';
import { ContactForm } from './ContactForm';
import { Gallery } from './Gallery';
import DiscordButton from './DiscordButton';
import imageAndroidLegacy from './images/android_legacy.jpg';
import imageJenny from './images/jenny.jpg';
import imageIGicon from './images/ig-icon-small.png';
import imageFBicon from './images/fb-icon-small.png';
import { FlexContainer, Caption, Header, Paragraph } from './styles';

const Image = styled.img`
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 35px 0px 35px;
`;

// const SectionHeader = styled.div`
//   margin-bottom: 10px;
//   font-family: 'Open Sans', sans-serif;
//   font-size: 16px;
//   font-weight: 600;
// `;

const FollowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const Break = styled.div`
  width: 100%;
  height: 1.5em;
`;

const images = [
  {
    src: imageAndroidLegacy,
    alt: "Android Legacy: The Grid by Oliver Wetter",
    caption: "art by oliver wetter"
  },
  {
    src: imageJenny,
    alt: "Jenny by Lesha Porche",
    caption: "art by lesha porche"
  },
];

export default function Home() {
  return (
    <div>
      <FlexContainer>
        <LeftColumn>
          <Gallery images={images} />
        </LeftColumn>

        <RightColumn>
          <Paragraph>
            It's the year 2071, and artificially-intelligent androids are ubiquitous in society: they cook and clean, construct and destruct; they do our dirty work, even fight on battlefields for us. But though they may look and act like humans, they're not conscious, and they can never become conscious—<BoldSpan>at least, that's what their manufacturers would have you believe.</BoldSpan>
            <Break></Break>
            <BoldSpan>You play a newly-awakened Sentient,</BoldSpan> a self-aware constructed entity, fighting for survival and freedom in a world that doesn’t even acknowledge your existence.
            <Break></Break>
            <BoldSpan>S E N T I E N T S is a tabletop roleplaying game of liberation, underground resistance, newfound emotion, and what it means to be something other than human.</BoldSpan>
            <Break></Break>
            Now available for purchase: the <a href="https://t3dbot.gumroad.com/l/sentients-core-rulebook-pdf" target="_blank">Sentients Core Rulebook PDF</a>!
          </Paragraph>
          <br/><br/>
          <Paragraph>
            <Header>FOLLOW &amp; DISCUSS</Header>
            
            <FollowContainer>
              <a href="https://www.instagram.com/sentientsrpg" target="_blank">
                <Image src={imageIGicon} alt="Follow on Instagram" />
              </a>
              &nbsp;&nbsp;
              <a href="https://www.facebook.com/profile.php?id=61557928403465" target="_blank">
                <Image src={imageFBicon} alt="Follow on Facebook" />
              </a>
              &nbsp;&nbsp;
              <DiscordButton isSSO={false} url={"https://discord.gg/p4m6DQUjjY"} />
            </FollowContainer>
          </Paragraph>
          <br/><br/>
          <Paragraph>
            <Header>SIGN UP FOR NEWS</Header>
            <ContactForm />
          </Paragraph>
          
          

          <Caption>GAME DESIGN BY TED HAYES</Caption>
        </RightColumn>
      </FlexContainer>
    </div>
  );
}