import {
  Paragraph,
  ColumnsContainer,
  Caption,
  Header,
  Column,
  OuterContainer
} from "./styles";
import styled from "styled-components";
import { Link } from "react-router-dom";

import imageCharSheet from "./images/character-sheet-thumbnail.png";
import imageDatabase from "./images/component-database-thumbnail.png";
import imageBuilder from "./images/character-builder-thumbnail.png";
import imageQuickRef from "./images/quick-ref-thumbnail.png";
import fofThumb from './images/fear-of-fire-thumb.png';
import fulExtThumb from './images/ful-ext-thumb.png';
import fulIntGndThumb from './images/ful-int-gnd-thumb.png';
import fulIntLowThumb from './images/ful-int-low-thumb.png';

import charSheet from "./files/sentients-character-sheet-v0.88.pdf";
import quickRef from "./files/sentients-quick-reference-v2.0.pdf";
import fofPDF from './files/fear-of-fire-zones.pdf';
import fulExt from './files/fulfillment-exterior.pdf'
import fulIntGnd from './files/fulfillment-int-ground.pdf'
import fulIntLow from './files/fulfillment-int-lower.pdf'

const Image = styled.img`
`;

const ALink = styled.a`
  color: inherit;
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

export default function Resources(){
  return (
    <OuterContainer>
      <ColumnsContainer>
        <Column>
          <Paragraph>
            <Header>CHARACTER BUILDER</Header>
            Check out the interactive <strong><ALink href="https://builder.sentientsrpg.com/" target="_blank">Character Builder</ALink></strong> web app. Requires a free account.
            <br/><br/>
            <a href="https://builder.sentientsrpg.com" target="_blank">
              <Image src={imageBuilder} alt="Sentients Character Builder Screenshot" />
            </a>
          </Paragraph>
          <br/><br/>
          <Paragraph>
            <Header>SCENARIO MAPS</Header>
            PDF maps for the scenarios included in the core rulebook.
            <br/><br/>
            <strong>"Fear of Fire" Zone Map</strong>
            <a href={fofPDF} target="_blank">
              <Image src={fofThumb} alt="'Fear of Fire' map" />
            </a>

            <br/><br/>
            <strong>"Fulfillment" Exterior</strong>
            <a href={fulExt} target="_blank">
              <Image src={fulExtThumb} alt="'Fulfillment' exterior" />
            </a>

            <br/><br/>
            <strong>"Fulfillment" Interior Ground Level</strong>
            <a href={fulIntGnd} target="_blank">
              <Image src={fulIntGndThumb} alt="'Fulfillment' Interior Ground Level" />
            </a>

            <br/><br/>
            <strong>"Fulfillment" Interior Lower Level</strong>
            <a href={fulIntLow} target="_blank">
              <Image src={fulIntLowThumb} alt="'Fulfillment' Interior Lower Level" />
            </a>
          </Paragraph>
        </Column>
        <Column>
          <Paragraph>
            <Header>CHARACTER SHEET</Header>
            The PDF <strong><ALink href={charSheet} target="_blank">Sentients Character Sheet</ALink></strong>.
            <br/><br/>
            <a href={charSheet} target="_blank">
              <Image src={imageCharSheet} alt="Sentients Character Sheet" />
            </a>
          </Paragraph>
          <Paragraph>
            <Header>COMPONENT DATABASE</Header>

            Use the <strong>
              <ALink href="https://components.sentientsrpg.com/" target="_blank">
                Component Database
              </ALink></strong> to easily search and browse anthroid components.
            <br/><br/>
            <a href="https://components.sentientsrpg.com/" target="_blank">
              <Image src={imageDatabase} alt="Sentients Interactive Component Database" />
            </a>
          </Paragraph>
          <br/><br/>
          <Paragraph>
            <Header>QUICK REFERENCE</Header>
            The <strong><ALink href={quickRef} target="_blank">Quick Reference Sheet</ALink></strong> provides tons of useful gameplay information at a glance.
            <br/><br/>
            <a href={quickRef} target="_blank">
              <Image src={imageQuickRef} alt="Sentients Quick Reference Sheet" />
            </a>
          </Paragraph>
        </Column>
      </ColumnsContainer>
      <Caption>GAME DESIGN BY TED HAYES</Caption>
      <br /><br />
    </OuterContainer>
  )
};